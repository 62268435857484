<template>
  <v-container fluid @keyup.escape="close()">
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="close" id="exit" ref="exit">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-form id="form" :readonly="readonly" ref="form">
              <v-text-field
                v-model="form.date"
                :clearable="!readonly"
                id="date"
                :label="$t(`Cols.${table}.date`) + star"
                ref="date"
                :rules="[required]"
                type="date"
              ></v-text-field>
              <v-text-field
                v-model="form.count_of_items"
                :clearable="!readonly"
                id="count_of_items"
                :label="$t(`Cols.${table}.count_of_items`) + star"
                maxlength="9"
                ref="count_of_items"
                :rules="[required, numeric]"
                validate-on-blur
              ></v-text-field>
              <v-textarea
                v-model="form.description"
                auto-grow
                :clearable="!readonly"
                id="description"
                :label="$t(`Cols.${table}.description`)"
                maxlength="1024"
                ref="description"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
              <v-textarea
                v-model="form.note"
                auto-grow
                :clearable="!readonly"
                id="note"
                :label="$t(`Cols.${table}.note`)"
                maxlength="1024"
                ref="note"
                rows="2"
                :rules="[]"
                validate-on-blur
              ></v-textarea>
            </v-form>
            <v-alert v-model="showWarning" dense outlined type="info">{{
              warning
            }}</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="action == 'create' || action == 'clone'"
              @click="store"
              :color="actionColor"
              dark
              id="save"
              >{{ $t("save") }}
            </v-btn>
            <v-btn
              v-if="action == 'edit'"
              @click="update"
              :color="actionColor"
              dark
              id="update"
              >{{ $t("update") }}
            </v-btn>
            <v-btn
              v-if="action == 'delete'"
              @click="destroy"
              :color="actionColor"
              dark
              id="delete"
              >{{ $t("delete") }}
            </v-btn>
            <v-btn @click="close" :color="actionColor" dark id="close">{{
              $t("close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

const TABLE = "outflows";

export default {
  data() {
    return {
      action: "show",
      autocompletes: [],
      form: {},

      formats: {
        date: "Date",
        count_of_items: "Integer6",
      },

      items: {},

      loading: false,
      required: (v) => !!v || this.$t("fieldRequired"),

      selects: [],
      table: TABLE,
    };
  },

  computed: {
    actionColor() {
      return this.$settings.actionColors[this.action];
    },

    moneySymbol() {
      return this.$helper.moneySymbol();
    },

    readonly() {
      return this.action == "delete" || this.action == "show";
    },

    showWarning() {
      return this.warning !== null;
    },

    star() {
      return this.readonly ? "" : " *";
    },

    warning() {
      if (this.action == "delete" && this.$te(`delete_${TABLE}_warning`))
        return this.$t(`delete_${TABLE}_warning`);

      return null;
    },
  },

  created() {
    this.loading = true;
    this.action = this.$route.name.split(".")[1];

    this.$api
      .show(TABLE, this.$route.params.id, this.action)
      .then((response) => this.setForm(response.data.data[0]))
      .catch((e) => this.error(e))
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions({
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    actionSuccess() {
      return this.$t(this.action + "Success", [this.$helper.tTable(TABLE)]);
    },

    autocompleteItems(name, path) {
      if (!this.readonly) {
        this.loading = true;
        this.$api
          .list(path)
          .then((response) => (this.items[name] = response.data))
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    close() {
      this.$router.go(-1);
    },

    comboItems(name) {
      if (!this.readonly) {
        this.loading = true;
        this.$api
          .combo(TABLE, name)
          .then((response) => (this.items[name] = response.data))
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    destroy() {
      this.loading = true;

      this.$api
        .destroy(TABLE, this.form.id)
        .then(() => this.success())
        .catch((e) => this.error(e))
        .finally(() => (this.loading = false));
    },

    error(e) {
      if (this.$helper.errorStatus(e) == 403) this.$router.replace({ name: "forbidden" });

      let error = this.$helper.error(e);
      let message = "";

      if (error == "invalidData") {
        this.$helper.setFieldErrors(e, this);
        message = this.$t(error);
      } else if (error == "destroyError") {
        message = this.$t(`destroy_${TABLE}_error`);
      } else if (this.$te(error)) {
        message = this.$t(error, [this.$helper.tTable(TABLE)]);
      } else if (this.$helper.errorStatus(e) == "404") {
        message = this.$t("doesNotExist", [this.$helper.tTable(TABLE)]);
      } else {
        message =
          this.$t(this.action + "Error", [this.$helper.tTable(TABLE)]) +
          this.$helper.error(e);
      }

      this.alertError(message);
    },

    numeric(v) {
      if (v == undefined) return true; //Avoid warning "Error in beforeMount hook..."

      return /^[-+]?([\d,]*)?(\.\d*?)?$/.test(v.trim()) || this.$t("validNumber");
    },

    selectItems(name) {
      if (!this.readonly) {
        this.items[name] = this.$helper.selectItems(name);
      }
    },

    setForm(item) {
      if (JSON.stringify(item) === JSON.stringify({}))
        return this.$t("doesNotExist", [this.$helper.tTable(TABLE)]);

      this.$helper.formatItem(item, this.formats);

      this.form = item;

      this.autocompletes.forEach((name) => {
        this.items[name] = [{ text: item[name.slice(0, -3)], value: item[name] }];
      });

      this.selects.forEach((name) => {
        if (item[name]) {
          let text = this.$helper.formatSelect(item[name], name);
          this.items[name] = [{ text: text, value: item[name] }];
        }
      });

      this.$refs.form.resetValidation();

      this.$refs.exit.$el.focus();
    },

    store() {
      if (this.validForm()) {
        this.loading = true;
        this.$api
          .store(TABLE, this.form)
          .then(() => this.success())
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    success() {
      this.alertSuccess(this.actionSuccess());
      this.close();
    },

    update() {
      if (this.validForm()) {
        this.loading = true;
        this.$api
          .update(TABLE, this.form.id, this.form)
          .then(() => this.success())
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
